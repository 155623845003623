import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {useParams, useSearchParams} from "react-router-dom";
import {generatePreviewUrl} from "../../utils/utils";
import {SendoutsApi} from "../../api/sendouts-api";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {CampaignSelectorDialog} from "../CampaignSelectorDialog/CampaignSelectorDialog";
import {CampaignType} from "../../models/marketo-config";

/**
 * Represents a preview page component.
 * @function PreviewPage
 * @returns {React.ReactElement} The preview page component.
 */
export const PreviewPage: React.FC = () => {

    const params = useParams();
    const [query] = useSearchParams();
    const sendoutId = params.sendoutId!;
    const [productForCampaignChange, setProductForCampaignChange] = useState<string | null>(null);
    const [showChangeDialog, setShowChangeDialog] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState<number | null>(null);

    const isDigest = query.get("digest") === "true";
    console.log("is digest: ", isDigest);

    const previewUrl = generatePreviewUrl(sendoutId, selectedCampaign, isDigest);

    useEffect(() => {
        (async () => {
            const response = await fetch(previewUrl, {credentials: "include"});
            if (response.status === 500) {
                const json = await response.json();
                const message: string = json.message ?? "";
                if (message.toLowerCase().includes("invalid target campaign")) {
                    // it seems like a campaign used for this sendout doesn't exist anymore
                    SendoutsApi.getSendout(sendoutId).subscribe(
                        {
                            next: (sendout => {
                                setProductForCampaignChange(sendout.product);
                            })
                        }
                    );
                }
            }
        })();
    }, [sendoutId]);

    function onCampaignSelected(campaignId: number) {
        setSelectedCampaign(campaignId);
        setShowChangeDialog(false);
    }

    return <>
        {productForCampaignChange && <S.CampaignChangeWarning>
            It seems like the campaign used for this sendout is not available anymore. You can
            <Button
                text
                style={{textDecoration: "underline", padding: "0 8px", transform: "translateY(-1px)"}}
                onClick={() => setShowChangeDialog(true)}
            >
                choose another one
            </Button>
            to re-generate a preview.
        </S.CampaignChangeWarning>}
        <iframe
            title="sendout preview"
            src={previewUrl}
            width="100%"
            height="100%"
            style={{border: "none"}}
        />

        {productForCampaignChange && <CampaignSelectorDialog
            show={showChangeDialog}
            productTypes={[productForCampaignChange]}
            onClose={() => setShowChangeDialog(false)}
            onCampaignSelected={onCampaignSelected}
            allowedCampaignTypes={[CampaignType.SINGLE_POST_SENDOUTS, CampaignType.UNIVERSAL]}
        />}
    </>;

};
