import React, {useEffect} from "react";
import * as S from "./styles";
import * as SS from "../common-styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import Pager from "@jetbrains/ring-ui/dist/pager/pager";
import ErrorMessage from "@jetbrains/ring-ui/dist/error-message/error-message";
import {AiOutlineFrown} from "react-icons/ai";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {SendoutState} from "../../models/sendout";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import {Sendout} from "../Sendout/Sendout";
import {StatusPanel} from "../StatusPanel/StatusPanel";
import {productToDropdownOption} from "../../utils/utils";

export const SendoutsPage: React.FC = () => {

    const {sendoutsPage, sendoutsStateFilter, sendoutsProductFilter, onlyOwnSendoutsFilter} = useAppSelector(state => state.sendouts);
    const products = useAppSelector(state => state.content.products);
    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const allowedProducts = products?.filter(p => p.permitted && p.value !== "CrossPost") ?? [];
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(sendoutsActions.loadSendouts({page: 0}));
    }, []);

    const onPageChange = (_newPage: number) => {
        const newPage = _newPage - 1;
        if (newPage >= 0 && newPage < sendoutsPage!.totalPages) {
            console.log("new page: " + newPage);
            dispatch(sendoutsActions.loadSendouts({page: newPage}));
        }
    };

    const toggleOnlyOwnSendoutsFiltering = () => {
        dispatch(sendoutsActions.setOnlyOwnSendoutsFilter(!onlyOwnSendoutsFilter));
    };

    if (sendoutsPage === null) {
        return <Loader message="Loading sendouts"/>;
    } else {
        if (newUiEnabled) {
            return <SS.PageLayout>
                <SS.SidePanel>
                    <S.FilterBlock>
                        <p>State filter:</p>
                        <Select
                            selected={sendoutStateToDropdownOption(sendoutsStateFilter)}
                            data={([null, "DRAFT", "SENT", "FAILED", "SCHEDULED", "CANCELLED", "BLOCKED"] as (SendoutState | null)[]).map(sendoutStateToDropdownOption)}
                            onChange={(s: any) => dispatch(sendoutsActions.setSendoutStateFilter(s?.value))}
                        />
                    </S.FilterBlock>
                    {allowedProducts.length > 1 && <S.FilterBlock>
                        <p>Product:</p>
                        <Select
                            selected={productToDropdownOption(sendoutsProductFilter)}
                            data={[null, ...allowedProducts].map(productToDropdownOption)}
                            onChange={(p: any) => dispatch(sendoutsActions.setSendoutsProductFilter(p?.value))}
                        />
                    </S.FilterBlock>}
                    <S.FilterBlock style={{marginTop: "30px"}}>
                        <Toggle
                            checked={onlyOwnSendoutsFilter}
                            onChange={toggleOnlyOwnSendoutsFiltering}
                        >
                            Show only my own sendouts
                        </Toggle>
                    </S.FilterBlock>
                </SS.SidePanel>
                <div style={{flexGrow: 1, overflow: "auto", padding: "0 0 8px"}}>
                    <StatusPanel newUi={true}/>
                    {sendoutsPage.content.length > 0 && <>
                        <div style={newUiEnabled ? {display: "flex", flexFlow: "row wrap", gap: "48px", justifyContent: "center", margin: "30px 0"} : {}}>
                            {sendoutsPage.content.map(sendout => <Sendout
                                sendout={sendout}
                                key={sendout.id}
                                newUi={true}
                            />)}
                        </div>
                        <Pager
                            total={sendoutsPage.totalElements}
                            currentPage={sendoutsPage.number + 1}
                            visiblePagesLimit={3}
                            disablePageSizeSelector
                            onPageChange={cp => onPageChange(cp)}
                            pageSize={sendoutsPage.size}
                        />
                    </> || <div style={{marginTop: "40px"}}>
                        <ErrorMessage
                            message="Nothing found"
                            description="There are no matching existing sendouts you have access to"
                            icon={AiOutlineFrown}
                        />
                    </div>}
                </div>
            </SS.PageLayout>;
        } else {
            return <div style={{padding: "8px"}}>
                <StatusPanel newUi={false}/>
                <S.FiltersPanel>
                    <div>
                        <S.FilterLabel>State filter:</S.FilterLabel>
                        <Select
                            selected={sendoutStateToDropdownOption(sendoutsStateFilter)}
                            data={([null, "DRAFT", "SENT", "FAILED", "SCHEDULED", "CANCELLED", "BLOCKED"] as (SendoutState | null)[]).map(sendoutStateToDropdownOption)}
                            onChange={(s: any) => dispatch(sendoutsActions.setSendoutStateFilter(s?.value))}
                        />
                    </div>
                    {allowedProducts.length > 1 && <div>
                        <S.FilterLabel>Product:</S.FilterLabel>
                        <Select
                            selected={productToDropdownOption(sendoutsProductFilter)}
                            data={[null, ...allowedProducts].map(productToDropdownOption)}
                            onChange={(p: any) => dispatch(sendoutsActions.setSendoutsProductFilter(p?.value))}
                        />
                    </div>}
                    <Toggle checked={onlyOwnSendoutsFilter} onChange={toggleOnlyOwnSendoutsFiltering}>Show only my own sendouts</Toggle>
                </S.FiltersPanel>
                {sendoutsPage.content.length > 0 && <>
                    <div>
                        {sendoutsPage.content.map(sendout => <Sendout sendout={sendout} key={sendout.id} newUi={false}/>)}
                    </div>
                    <Pager
                        total={sendoutsPage.totalElements}
                        currentPage={sendoutsPage.number + 1}
                        visiblePagesLimit={3}
                        disablePageSizeSelector
                        onPageChange={cp => onPageChange(cp)}
                        pageSize={sendoutsPage.size}
                    />
                </> || <div style={{marginTop: "40px"}}>
                    <ErrorMessage
                        message="Nothing found"
                        description="There are no matching existing sendouts you have access to"
                        icon={AiOutlineFrown}
                    />
                </div>}
            </div>;
        }
    }

};

function sendoutStateToDropdownOption(state: SendoutState | null) {
    return state ? {
        label: state,
        value: state,
        key: state,
    } : {
        label: "ALL",
        value: null,
        key: "null",
    };
}
