import React, {useEffect} from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import {AppPage} from "../../models/pages";
import {FeedPage} from "../FeedPage/FeedPage";
import {Header} from "../Header/Header";
import {SendoutsPage} from "../SendoutsPage/SendoutsPage";
import * as S from "./styles";
import {EditorPage} from "../EditorPage/EditorPage";
import {UsersPage} from "../UsersPage/UsersPage";
import {useAppDispatch} from "../../redux/store";
import {usersActions} from "../../redux/slices/users-slice";
import {ConfigPage} from "../ConfigPage/ConfigPage";
import {Footer} from "../Footer/Footer";
import {PreviewPage} from "../PreviewPage/PreviewPage";
import {StatisticsPage} from "../StatisticsPage/StatisticsPage";
import {MotdDialog} from "../MotdDialog/MotdDialog";
import {miscActions} from "../../redux/slices/misc-slice";
import {DigestEditorPage} from "../DigestEditorPage/DigestEditorPage";
import {DigestsPage} from "../DigestsPage/DigestsPage";

export function AppRoot() {

    const dispatch = useAppDispatch();

    useEffect(() => {
        // kind of app initialization:
        dispatch(miscActions.onInit());
        dispatch(usersActions.loadCurrentUserInfo());
    }, []);

    return (
        <S.RootLayout>
            <Header/>
            <S.Main>
                <Routes>
                    <Route path={AppPage.FEED} element={<FeedPage/>} />
                    <Route path={AppPage.SENDOUTS} element={<SendoutsPage/>} />
                    <Route path={AppPage.EDITOR + "/:sendoutId"} element={<EditorPage/>} />
                    <Route path={AppPage.DIGEST_EDITOR + "/:digestId"} element={<DigestEditorPage/>} />
                    <Route path={AppPage.USERS} element={<UsersPage/>} />
                    <Route path={AppPage.CONFIG} element={<ConfigPage/>} />
                    <Route path={AppPage.PREVIEW + "/:sendoutId"} element={<PreviewPage/>} />
                    <Route path={AppPage.STATISTICS} element={<StatisticsPage/>} />
                    <Route path={AppPage.DIGESTS} element={<DigestsPage/>} />
                    <Route path="/*" element={<Navigate to={AppPage.FEED}/>}/>
                </Routes>
                <MotdDialog/>
            </S.Main>
            <Footer/>
        </S.RootLayout>
    );

}
