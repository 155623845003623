import React, {ReactNode, useMemo} from "react";
import * as S from "./styles";
import {ShortDigestInfo} from "../../models/short-digest-info";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {Icon} from "../Icon/Icon";
import {FaRegCopy, FaRegEdit} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../redux/store";
import {digestsActions} from "../../redux/slices/digests-slice";
import {MdDelete, MdOutlinePreview} from "react-icons/md";
import {resolveBadgeStyles} from "../../utils/utils";

interface Props {
    shortInfo: ShortDigestInfo;
}

interface Action {
    fn(): void;
    label: string;
    primary?: boolean;
    danger?: boolean;
    icon: ReactNode;
}

export const DigestCard: React.FC<Props> = (props) => {

    const {shortInfo} = props;

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const images: string[] = shortInfo
        .posts
        .filter(i => i.imageUrl)
        .map(i => i.imageUrl!)
        .slice(0, 3);

    const badgeStyle = useMemo(() => resolveBadgeStyles(shortInfo.state), [shortInfo.state]);

    const availableActions: Action[] = [
        {
            label: "Copy",
            fn: copy,
            icon: <Icon Type={FaRegCopy}/>,
        },
        {
            label: "Open preview",
            fn: openPreview,
            icon: <Icon Type={MdOutlinePreview}/>,
        }
    ];

    if (shortInfo.state === "DRAFT") {
        availableActions.unshift({
            label: "Edit",
            fn: edit,
            primary: true,
            icon: <Icon Type={FaRegEdit}/>,
        });
        availableActions.push({
            label: "Delete",
            fn: remove,
            danger: true,
            icon: <Icon Type={MdDelete}/>,
        });
    }

    function edit(): void {
        navigate(`/digest-editor/${shortInfo.id}`);
    }

    function copy(): void {
        dispatch(digestsActions.copyDigest(shortInfo.id));
    }

    function remove(): void {
        dispatch(digestsActions.deleteDigest(shortInfo.id));
    }

    function openPreview(): void {
        window.open(`/preview/${shortInfo.id}?digest=true`, "_blank");
    }

    return <S.Card>
        <S.ImagesStack>
            {images.map((i, idx) => {
                let offset = getOffset(idx, images.length);
                return <S.Image src={i} offset={offset} z={10-idx} key={i}/>;
            })}
        </S.ImagesStack>
        <S.DataContainer>
            <S.Title>{shortInfo.title}</S.Title>
            <S.Meta>
                A digest of&nbsp;
                <Tooltip title={shortInfo.posts.map((p, idx) => <S.MiniTitle key={idx}>- {p.title}</S.MiniTitle>)}>
                    <b>{shortInfo.posts.length}</b>
                </Tooltip>
                &nbsp;items
            </S.Meta>
            <S.Meta><b>Status:</b> <S.Badge style={{...badgeStyle}}>{shortInfo.state}</S.Badge></S.Meta>
            <S.Meta><b>Created</b> at {shortInfo.createdAt.toLocaleString("ru-RU")} by {shortInfo.creatorEmail}</S.Meta>
            {shortInfo.sentAt && <S.Meta><b>Sent</b> at {shortInfo.sentAt.toLocaleString("ru-RU")}</S.Meta>}
        </S.DataContainer>
        <S.ButtonsContainer>
            {availableActions.length > 0 && availableActions.map((action, idx) => {
                if (idx === 0)
                    return <S.Button onClick={action.fn} key={idx}>
                        {action.icon}
                        &nbsp;
                        {action.label}
                    </S.Button>;
                else {
                    return <S.IconButtonWrapper>
                        <Tooltip title={action.label} key={idx}>
                            <S.IconButton
                                onClick={action.fn}
                                mainColor={action.danger ? "#ff6767" : "#AAA"}
                                hoverColor={action.danger ? "#F64C49" : "#777"}
                            >
                                {action.icon}
                            </S.IconButton>
                        </Tooltip>
                    </S.IconButtonWrapper>;
                }
            })}
        </S.ButtonsContainer>
    </S.Card>;

};

const baseOffset = 16;
function getOffset(idx: number, totalLength: number): number {
    const middle = (totalLength - 1) / 2;
    return (idx - middle) * baseOffset;
}