export enum AppPage {
    FEED = "/feed",
    SENDOUTS = "/sendouts",
    EDITOR = "/editor",
    USERS = "/users",
    CONFIG = "/config",
    PREVIEW = "/preview",
    STATISTICS = "/statistics",
    DIGEST_EDITOR = "/digest-editor",
    DIGESTS = "/digests",
}
