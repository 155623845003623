import styled from "styled-components";

// TODO: move to common?
export const Card = styled.div`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 400px;
  height: 460px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: whitesmoke;
  position: relative;
`;

export const DataContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  flex-shrink: 0;
`;

export const Meta = styled.p`
  margin: 0;
  color: #777;
  font-size: 0.8em;
  text-align: center; 
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
`;

export const Button = styled.button`
    background-color: #4e9fe1;
    border: none;
    color: ghostwhite;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition: background-color ease-in-out 200ms;

    &:hover {
        background-color: #0380E5;
    }
`;

export const IconButtonWrapper = styled.div`
  &:not(:last-child) {
    border-right: 1px solid #999;
  }
`;

export const IconButton = styled(Button)<{mainColor: string, hoverColor: string}>`
  background-color: ${props => props.mainColor};
  width: 56px;
  &:hover {
    background-color: ${props => props.hoverColor};
  }
`;

export const ImagesStack = styled.div`
  height: 200px;
  position: relative;
  margin-top: 24px;
`;

export const Image = styled.img<{offset: number, z: number}>`
  height: 85%;
  position: absolute;
  inset: auto 0 0 0;
  margin: auto;
  border-radius: 8px;
  transform: translate(${props => props.offset}px, ${props => props.offset * -1}px);
  z-index: ${props => props.z};
  &:hover {
    z-index: 16;
  }
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1em;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const MiniTitle = styled.p`
  font-size: 0.8em;
  margin: 2px 0;
`;

export const Badge = styled.span`
  font-size: 0.8em;
  padding: 2px 4px;
  display: inline-block;
  color: black;
  border: 1px solid #555;
  border-radius: 2px;
`;