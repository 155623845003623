import React, {useEffect} from "react";
import * as S from "./styles";
import classNames from "classnames";
import logo from "./mau-logo.png";
import newLogo from "./new-logo.png";
import {AppPage} from "../../models/pages";
import {useLocation} from "react-router-dom";
import RingHeader from "@jetbrains/ring-ui/dist/header/header";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {Counter} from "../Counter/Counter";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import {miscActions} from "../../redux/slices/misc-slice";

export const Header: React.FC = () => {

    const currentUser = useAppSelector(state => state.users.currentUserInfo);
    const recentSendoutsCount = useAppSelector(state => state.sendouts.recentSendoutsCount);
    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const dispatch = useAppDispatch();
    const {pathname} = useLocation();
    const currentPage = pathname as AppPage;

    const isAdmin = currentUser?.isAdmin ?? false;
    const isFeatureTester = currentUser?.isFeatureTester ?? false;

    useEffect(() => {
        dispatch(sendoutsActions.updateRecentSendoutsCount());
    }, []);

    const renderLink = (page: AppPage, label: string) => {
        const classnames = classNames({
            selected: page === currentPage,
        });
        return <S.HeaderLink to={page} className={classnames}>
            {label}
        </S.HeaderLink>;
    };

    function toggleNewUi() {
        dispatch(miscActions.setNewUiState(!newUiEnabled));
    }

    return <RingHeader>
        <S.Logo src={newUiEnabled ? newLogo : logo} alt="app-logo"/>
        <S.HeaderContent>
            <h1>JetBlog</h1>
            <S.LinksBlock style={newUiEnabled ? {marginLeft: "100px"} : {}}>
                {renderLink(AppPage.FEED, "Feed")}
                <Counter value={recentSendoutsCount ?? 0} hideOnZero={true}>
                    <S.HeaderLink to={AppPage.SENDOUTS} className={classNames({selected: currentPage === AppPage.SENDOUTS})}>
                        Sendouts
                    </S.HeaderLink>
                </Counter>
                {(isFeatureTester || isAdmin) && renderLink(AppPage.DIGESTS, "Digests")}
                {isAdmin && renderLink(AppPage.USERS, "Users")}
                {isAdmin && renderLink(AppPage.CONFIG, "Config")}
                {isAdmin && renderLink(AppPage.STATISTICS, "Statistics")}
            </S.LinksBlock>
            <div style={{marginRight: "40px"}}>
                <Toggle defaultChecked={newUiEnabled} onChange={toggleNewUi}>
                    <label>Enable new UI</label>
                </Toggle>
            </div>
            {currentUser && <S.UserInfoBlock>
                {currentUser.picture &&
                    <S.UserAvatar src={currentUser.picture} alt="user avatar" referrerPolicy="no-referrer" rounded={newUiEnabled}/>}
                <span>{currentUser.name.split(" ")[0]}</span>
            </S.UserInfoBlock>}
        </S.HeaderContent>
    </RingHeader>;

};
