import {SendoutState} from "./sendout";

export class ShortDigestInfo {
    readonly createdAt: Date;
    readonly sentAt: Date | null;
    constructor(
        public readonly id: string,
        public readonly title: string,
        public readonly product: string,
        public readonly state: SendoutState,
        createdAt: Date,
        sentAt: Date | null,
        public readonly creatorEmail: string,
        public readonly posts: {
            contentId: string;
            title: string;
            imageUrl: string | null | undefined;
        }[],
    ) {
        this.createdAt = new Date(createdAt);
        this.sentAt = sentAt ? new Date(sentAt) : null;
    }

    static parse(obj: any): ShortDigestInfo {
        return new ShortDigestInfo(
            obj.id,
            obj.title,
            obj.product,
            obj.state,
            obj.createdAt,
            obj.sentAt,
            obj.creatorEmail,
            obj.posts,
        )
    }
}