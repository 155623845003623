import {FeatureRole, UserRole} from "./user";

export class CurrentUserInfo {

    readonly createdAt: Date;

    constructor(
        public readonly name: string,
        public readonly email: string,
        public readonly picture: string | null,
        public readonly roles: UserRole[],
        createdAt: string,
    ) {
        this.createdAt = new Date(createdAt);
    }

    get isDeveloper() {
        return this.email === "vlad.fedorenkov@jetbrains.com";
    }

    get isAdmin(): boolean {
        return this.roles.includes("MAU_TEAM");
    }

    get isFeatureTester() {
        return this.roles.includes("FEATURE_TESTER");
    }

    get featureRoles(): FeatureRole[] {
        return this.roles.filter(r => r !== "REGULAR_USER" && r !== "MAU_TEAM") as FeatureRole[];
    }

    static parse(obj: any): CurrentUserInfo {
        return new CurrentUserInfo(
            obj.name,
            obj.email,
            obj.picture ?? null,
            obj.roles ?? [],
            obj.createdAt,
        );
    }

}
