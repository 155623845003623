import styled from "styled-components";

export const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
`;

export const FilterBlock = styled.div`
  margin-bottom: 10px;
  p {
    margin-bottom: 4px;
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
`;
