import React, {useEffect} from "react";
import * as S from "./styles";
import * as SS from "../common-styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {productToDropdownOption} from "../../utils/utils";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {digestsActions} from "../../redux/slices/digests-slice";
import {DigestCard} from "../DigestCard/DigestCard";

export const DigestsPage: React.FC = () => {

    const dispatch = useAppDispatch();

    const {digestSendoutsProductFilter, loadedDigestSendouts} = useAppSelector(state => state.digests);
    const products = useAppSelector(state => state.content.products);
    const allowedProducts = products?.filter(p => p.permitted && p.value !== "CrossPost") ?? [];

    useEffect(() => {
        if (loadedDigestSendouts === null) {
            dispatch(digestsActions.loadDigests());
        }
    }, [loadedDigestSendouts]);

    return <SS.PageLayout>
        <SS.SidePanel>
            {allowedProducts.length > 1 && <S.FilterBlock>
                <p>Product:</p>
                <Select
                    selected={productToDropdownOption(digestSendoutsProductFilter)}
                    data={[null, ...allowedProducts].map(productToDropdownOption)}
                    onChange={(p: any) => dispatch(digestsActions.setDigestSendoutsProductFilter(p?.value))}
                />
            </S.FilterBlock>}
        </SS.SidePanel>
        <S.Content>
            <S.CardsContainer>
                {loadedDigestSendouts?.map(sendout => <DigestCard shortInfo={sendout} key={sendout.id}/>)}
            </S.CardsContainer>
        </S.Content>
    </SS.PageLayout>;

};
